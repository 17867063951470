<template>
  <b-modal
    id="modal-stock-discount"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-between">
      <div />
      <h1 class="h4 text-dark fw-bold-700 mb-0 size16">
        Masukkan diskon tambahan % atau Rp
      </h1>
      <feather-icon
        class="text-dark fw-bold-700"
        size="20"
        icon="XIcon"
        @click="$bvModal.hide('modal-stock-discount')"
      />
    </div>
    <hr class="my-0">
    <b-container>
      <b-row class="p-1 mb-1">
        <b-col
          lg="12"
          class="px-0"
        >
          <b-card
            class="border-0 p-0 mb-0 w-100"
            style="box-shadow: none;"
            no-body
          >
            <div class="d-flex align-items-center justify-content-between w-100">
              <div
                class="d-flex align-items-center justify-content-between px-2 py-1 w-100"
                @click="addDiscount"
              >
                <div class="d-flex align-items-center">
                  <div class="rounded__frame align-self-center">
                    <b-img
                      width="20"
                      class="mr-25"
                      :src="require('@/assets/images/icons/discount.svg')"
                    />
                  </div>
                  <h6 class="text-primary font-weight-bolder mb-0">
                    Masukkan diskon tambahan % atau Rp
                  </h6>
                </div>
                <feather-icon
                  class="text-dark font-weight-bolder"
                  size="20"
                  icon="PlusIcon"
                />
              </div>
            </div>
            <b-row class="" v-if="discountStock.length > 0">
              <b-col cols="12">
                <hr class="mt-0">
              </b-col>
              <b-col
                v-for="(discount, index) in discountStock"
                :key="index"
                cols="12"
                class="mb-1"
              >
                <div class="d-flex px-2 align-items-center">
                  <h6
                    class="mb-0 w-50"
                  >
                    {{ index + 1 }}. Diskon
                  </h6>
                  <div v-if="discount.type === 'fixed'" class="w-100 form-control custom__input">
                    {{ discount.discount | formatAmount }}
                  </div>
                  <div v-else class="w-100 form-control custom__input">
                    {{ discount.discount }}%
                  </div>
                  <!-- <input
                    class="w-100 form-control custom__input"
                    :value="discount.discount"
                    type="number"
                    readonly
                    disabled
                  > -->
                  <div
                    class="align-self-center text-right ml-1"
                    role="button"
                    @click="editDiscount(index, discount)"
                  >
                    <feather-icon
                      size="24"
                      icon="EditIcon"
                      class="text-primary fw-bold-800"
                    />
                  </div>
                  <div
                    class="align-self-center text-right ml-1"
                    role="button"
                    @click="deleteDiscount(index)"
                  >
                    <feather-icon
                      size="24"
                      icon="Trash2Icon"
                      class="text-danger fw-bold-800"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button
        class="button__cancel"
        @click="$bvModal.hide('modal-stock-discount')"
      >
        Batal
      </b-button>
      <b-button
        class="button__save"
        @click="saveStockDiscount"
      >
        Simpan
      </b-button>
    </div>
    <ModalAddtionalInputDiscount :pricelist="pricelist" :is-stock="true" :formData="discount" />
  </b-modal>
</template>

<script>
// import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BModal, VBToggle, BButton, BContainer, BImg, BCard,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification, preformatFloat, formatRupiah } from '@/auth/utils'
import ModalAddtionalInputDiscount from '@/components/Stock/Modal/AdditionalInputDiscount.vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'cleave.js'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BButton,
    BImg,
    BCard,
    ModalAddtionalInputDiscount,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    cleave: {
      inserted: (el, binding) => {
        // eslint-disable-next-line no-param-reassign
        el.cleave = new Cleave(el, binding.value || {})
      },
      update: el => {
        const event = new Event('input', { bubbles: true })
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
        }, 100)
      },
    },
  },
  props: {
    discounts: {
      type: Array,
    },
    pricelist: {
      type: Number
    },
    summaryDiscount: {
      type: Function,
    },
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      is_ritel: true,
      is_discount: false,
      formPayload: {
        type: 'percent',
        value: 0,
      },
      discountStock: [],
      discount: {},
    }
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat, formatRupiah
    }
  },
  watch: {
    '$store.state.stockWarehouse.discounts': {
      handler(value) {
        if (this.$store.state.stockWarehouse.indexDiscounts !== false) {
          this.discountStock.splice(this.$store.state.stockWarehouse.indexDiscounts, 1)
          this.discountStock.splice(this.$store.state.stockWarehouse.indexDiscounts, 0, {
            type: value?.type,
            discount: value?.discount,
          })
          // this.summaryDiscount()
        } else {
          this.discountStock.push({
            type: value?.type,
            discount: value?.discount,
          })
          // this.summaryDiscount()
        }
      },
      deep: true,
    },
    discounts: {
      handler(value) {
        this.discountStock = value
      },
      deep: true,
    },
  },
  methods: {
    editDiscount(index, discount) {
      this.$store.commit('stockWarehouse/setIndexDiscounts', index)
      this.discount = discount
      this.$store.commit('stockWarehouse/setIsAddDiscount', true)
      this.$bvModal.show('modal-additional-input-discount')
    },
    deleteDiscount(index) {
      this.discounts.splice(index, 1)
    },
    addDiscount() {
      this.$store.commit('stockWarehouse/setIndexDiscounts', false)
      this.discount = {
        type: 'percent',
        discount: 0,
      }
      this.$store.commit('stockWarehouse/setIsAddDiscount', true)
      this.$bvModal.show('modal-additional-input-discount')
    },
    saveStockDiscount() {
      this.$emit('discount_stocks', this.discountStock)
      this.$bvModal.hide('modal-stock-discount')
    },
  },
}
</script>

<style lang="scss">
@import '/src/assets/scss/variables/_variables.scss';

#modal-stock-discount {
  .modal-dialog {

    .modal-content {
      background: #FCFCFC;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: $light--background;
            border: none;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
          .input-group {
            .input-group-append {
              .input-group-text {
                border: 1px solid $light--background;
                background-color: $light--background;
                color: $light--description;
                border-radius: 8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__is--discount {
            background: transparent !important;
            border: none !important;
            color: $light--description !important;

            &.active {
              background: #FFFFFF !important;
              border: none !important;
              color: $dark !important;
            }
          }

          .button__cancel {
            background-color: #FFFFFF !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45B6AB !important;
            border-color: #45B6AB !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

        }
      }
    }
  }
}
</style>
