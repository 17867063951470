<template>
  <b-container
    class="mt-3"
    :class="$router.name == 'payment.split' ? 'px-0' : ''"
  >
    <b-row class="justify-content-center bg-white">
      <b-col
        :class="$router.name == 'payment.split' ? 'px-0' : ''"
        :cols="
          $route.name == 'stock.add.index' || $route.name == 'debt.index'
            ? 12
            : column
        "
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <!-- Rekening Pengirim -->
            <b-col cols="12" class="mb-2">
              <b-form-group class="custom__form--input mb-0">
                <label for=""
                  >Rekening
                  {{
                    this.$route.name === "stock.add.index"
                      ? "Pengirim"
                      : "Penerima"
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-select
                  v-if="this.$route.name === 'stock.add.index'"
                  v-model="formPayload.bank_account_payment_uuid"
                  :options="optionsBankAccount"
                  @change="getBankAccountDetail"
                  required
                />
                <b-form-select
                  v-else
                  v-model="formPayload.bank_account_payment_uuid"
                  :options="optionsBankAccountSupplier"
                  @change="getBankAccountDetail"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Masukan Nominal -->
            <b-col v-if="$route.name == 'payment.split'" cols="12" class="mb-2">
              <b-form-group class="custom__form--input mb-0">
                <label for=""
                  >Masukan Nominal <span class="text-danger">*</span></label
                >
                <input
                  :id="`amount-transfer-${indexNominal}`"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  :value="formPayload.cash_total_payment"
                  @input="getAmountTransfer"
                  @change="getAmountTransfer"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Pilih Bank Pengirim -->
            <b-col
              v-if="this.$route.name !== 'stock.add.index'"
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Pilih Bank Pengirim</label>
                <b-form-select
                  v-model="formPayload.bank_name"
                  :options="options"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Nama Pemilik Pengirim -->
            <b-col
              v-if="this.$route.name !== 'stock.add.index'"
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="v-account-owner">Nama Pemilik Pengirim</label>
                <b-form-input
                  id="v-account-owner"
                  v-model="formPayload.bank_account_name"
                  class="custom__input"
                  type="text"
                  placeholder="Nama Pemilik Pengirim"
                  required
                  pattern="^[a-zA-Z\s]+$"
                />
              </b-form-group>
            </b-col>

            <!-- Nomor Rekening -->
            <b-col
              v-if="this.$route.name !== 'stock.add.index'"
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="no-rek">Nomor Rekening</label>
                <b-form-input
                  id="no-rek"
                  v-model="formPayload.bank_account_number"
                  class="custom__input"
                  type="number"
                  placeholder="x-xxxx-xxxx"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- <b-col
              v-if="this.$route.name === 'stock.add.index'"
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Rekening Penerima <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="bank_account_supplier"
                  :options="optionsBankAccountSupplier"
                  required
                />
              </b-form-group>
            </b-col> -->

            <!-- Bukti Transfer (Optional) -->
            <b-col cols="12" class="mb-2">
              <label class="text-dark fw-bold-600 size14 mb-2"
                >Bukti Transfer (Optional)</label
              >
              <div class="d-flex">
                <div
                  v-if="image_url"
                  class="image__transfer--container mr-2"
                  :style="`background-image: url(${image_url})`"
                >
                  <div style="position: absolute; right: -10px; top: -10px">
                    <div
                      class="delete__image"
                      role="button"
                      @click="
                        (image_url = ''),
                          (formPayload.bank_transfer_attachment = '')
                      "
                    >
                      <feather-icon class="text-dark" icon="XIcon" size="22" />
                    </div>
                  </div>
                  <div
                    class="preview__image"
                    role="button"
                    @click="previewImage"
                  >
                    <feather-icon
                      class="text-white fw-bold-800"
                      icon="ZoomInIcon"
                      size="20"
                    />
                    <span class="text-white fw-bold-800">Lihat</span>
                  </div>
                </div>
                <div>
                  <h6
                    v-if="formPayload.bank_transfer_attachment"
                    class="text-dark fw-bold-600 size14 mb-2"
                  >
                    {{ formPayload.bank_transfer_attachment.name }}
                  </h6>
                  <label
                    for="file"
                    class="p-1 bg-white text-drak fw-bold-800 border border-8"
                    role="button"
                  >
                    Unggah Foto
                  </label>
                  <small class="font-italic">*max. 1 mb</small>
                  <input
                    id="file"
                    type="file"
                    accept="image/*"
                    style="opacity: 0; height: 0"
                    @change="changePhoto"
                  />
                </div>
              </div>
            </b-col>

            <!-- Button -->
            <b-col v-if="!isButtonHide" cols="12" class="mb-2 mt-2">
              <b-button type="submit" class="btn-block size16 py-1 border-8">
                Selanjutnya
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { formatRupiah } from "@/auth/utils";

export default {
  props: {
    submitTransferBank: {
      type: Function,
    },
    isButtonHide: {
      type: Boolean,
      default: false,
    },
    createUtang: {
      type: Function,
    },
    column: {
      type: Number,
      default: 5,
    },
    formData: {
      type: Object,
    },
    indexNominal: {
      type: Number,
    },
  },
  setup() {
    return {
      formatRupiah,
    };
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
  },
  data() {
    return {
      options: [],
      optionsBankAccount: [],
      optionsBankAccountSupplier: [],
      bank_account_supplier: {},
      image_url: "",
      formPayload: {
        bank_name: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_payment_uuid: "",
        bank_transfer_attachment: "",
        cash_total_payment: "",
      },
    };
  },
  watch: {
    formData: {
      handler(value) {
        this.formPayload = value;
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        const payload = { ...value };

        this.$emit("payloadBank", payload);
        this.$emit("formPayment", this.formPayload);
      },
      deep: true,
    },
    bank_account_supplier: {
      handler(value) {
        this.formPayload.bank_name = value.bank?.name;
        this.formPayload.bank_account_name = value.owner_name;
        this.formPayload.bank_account_number = value.account_number;
      },
      deep: true,
    },
  },
  created() {
    this.getBank();
    if (this.$route.name === "stock.add.index") {
      this.getBankAccounts();
      console.log('111')
    } else if (localStorage.getItem("is_piutang") == 'true') {
      console.log('222')
      this.getAccountGeneral();
    } else {
      console.log('333')
      this.getAccountSupplier();
    }
  },
  mounted() {
    if (this.$route.query.data) {
      const decodeData = JSON.parse(decodeURIComponent(this.$route.query.data));
      setTimeout(() => {
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (
            this.indexNominal === index &&
            element.payment_method == "transfer"
          ) {
            this.formPayload = {
              ...element,
            };
          }
        }
      }, 500);
    }
    setTimeout(() => {
      if (this.formPayload.bank_account_payment_uuid) {
        this.getBankAccountDetail();
      }
    }, 500);
  },
  destroyed() {
    this.formPayload = {
      bank_name: "",
      bank_account_name: "",
      bank_account_number: "",
      bank_account_payment_uuid: "",
      bank_transfer_attachment: "",
    };
  },
  methods: {
    getAmountTransfer() {
      const rupiah = document.getElementById(
        `amount-transfer-${this.indexNominal}`
      );
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayload.cash_total_payment = rupiah.value;
    },
    submitForm() {
      if (this.$route.name == "stock.add.index") {
        this.$emit("formPayment", this.formPayload);
        this.$bvModal.hide("modal-form-payment");
        return;
      }
      if (this.$route.name == "debt.index") {
        this.createUtang();
        return;
      }
      this.submitTransferBank();
    },
    previewImage() {
      const newTab = window.open();
      newTab.document.body.innerHTML = `<img src="${this.image_url}" width="400px">`;
      // window.location.href = this.image_url
    },
    changePhoto(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.bank_transfer_attachment = event.target.files[0];
      this.showPhoto(event.target.files[0]);
    },
    showPhoto(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image_url = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async getBank() {
      await this.$store
        .dispatch("bank/getData", {
          uuid: "",
          params: {
            per_page: 30,
          },
        })
        .then((result) => {
          const items = result.data.data.data;
          // eslint-disable-next-line array-callback-return
          items.map((el) => {
            this.options.push({
              value: el.name,
              text: el.name,
            });
          });
          this.options.unshift({ value: "", text: "Pilih Bank" });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getAccountGeneral() {
      await this.$store
        .dispatch("bankAccounts/getData", {
          params: {
            per_page: 100,
          },
        })
        .then((result) => {
          const items = result.data.data.data;
          // eslint-disable-next-line array-callback-return
          items.map((el) => {
            this.optionsBankAccountSupplier.push({
              value: el.uuid,
              text: `${el.owner_name} - ${el.bank?.name} - ${el.account_number}`,
            });
          });
          this.optionsBankAccountSupplier.unshift({
            value: "",
            text: "Pilih Rekening",
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getAccountSupplier() {
      await this.$store
        .dispatch("bankAccounts/getData", {
          params: {
            supplier_uuid: localStorage.getItem("customer_supplier_uuid") || "",
            per_page: 100,
          },
        })
        .then((result) => {
          const items = result.data.data.data;
          // eslint-disable-next-line array-callback-return
          items.map((el) => {
            this.optionsBankAccountSupplier.push({
              value: el.uuid,
              text: `${el.owner_name} - ${el.bank?.name} - ${el.account_number}`,
            });
          });
          this.optionsBankAccountSupplier.unshift({
            value: "",
            text: "Pilih Rekening Penerima",
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getBankAccounts() {
      await this.$store
        .dispatch("bankAccounts/getData", {
          params: {
            per_page: 100,
          },
        })
        .then((result) => {
          const items = result.data.data.data;
          const filtered = items.filter((el) => el.account_type === "Tabungan");
          // eslint-disable-next-line array-callback-return
          items.map((el) => {
            this.optionsBankAccount.push({
              value: el.uuid,
              text: `${el.owner_name} - ${el.bank?.name} - ${el.account_number}`,
            });
          });
          this.optionsBankAccount.unshift({
            value: "",
            text: "Pilih Rekening",
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getBankAccountDetail() {
      await this.$store
        .dispatch("bankAccounts/getDetail", {
          uuid: this.formPayload.bank_account_payment_uuid,
          params: "",
        })
        .then((result) => {
          const items = result.data.data;
          this.$emit("bankAccountDetail", items);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.image__transfer--container {
  position: relative;
  width: 167px;
  height: 112px;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;

  .preview__image {
    position: absolute;
    background: linear-gradient(
      178.74deg,
      rgba(0, 0, 0, 0) 1.08%,
      rgba(0, 0, 0, 0.5) 108.88%
    );
    display: flex;
    width: 169px;
    height: 59px;
    padding: 2rem 1rem;
    border-radius: 8px;
    bottom: 0;
  }

  .delete__image {
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0;
  }
}
</style>
