<template>
  <b-modal
    id="modal-stock-actual"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <div
        class="d-flex align-items-center justify-content-between"
      >
        <div
          style="cursor: pointer;"
          @click="$bvModal.hide('modal-stock-actual')"
        >
          <feather-icon
            size="24"
            class="text-dark fw-bold-600"
            icon="ArrowLeftIcon"
          />
          <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
        </div>
        <h1 class="text-dark h6 fw-bold-600 mb-0 size16">
          Detil Stok
        </h1>
        <div class="mr-5 pr-5" />
      </div>
    </header>
    <b-row class="mx-0 justify-content-center mt-5 pt-5">
      <b-col cols="5">
        <div v-if="detail.image_url" class="text-center">
          <b-img width="200" class="text-center" :src="detail.image_url" />
        </div>
        <!-- <b-carousel
          v-if="detail.image_url"
          id="carousel-example-generic"
          controls
          indicators
          :interval="10000"
        >
          <b-carousel-slide :img-src="detail.image_url" />
        </b-carousel> -->
        <div class="d-flex mb-1 mt-2">
          <b-img
            width="16"
            :src="require('@/assets/images/icons/papper.svg')"
          />
          <h6 class="text-dark text-darken-4 fw-bold-600 mb-0 size14 ml-1">
            SKU
          </h6>
        </div>
        <h4 class="text-dark fw-bold-600 size18 mb-1">
          {{ detail.sku_code || '-' }}
        </h4>
        <h5 class="text-primary text-dark fw-bold-700 size16 mb-1">
          Stok tersedia: {{ detail.stock || '-' }}
        </h5>
        <div class="d-flex justify-content-between">
          <span class="text-dark text-darken-4 size14 fw-bold-500">Terakhir stok opname</span>
          <div class="mr-5 pr-5">
            <h6 class="text-dark size14 fw-bold-500">
              {{detail.latest_stock_opname || '-'}}
            </h6>
            <!-- <a
              href="#"
              class="text-dark text-darken-4 size14 fw-bold-500"
            >
              <u>
                Lihat riwayat
              </u>
            </a> -->
          </div>
          <div />
        </div>
        <!-- Rincian -->
        <h4 class="text-dark fw-bold-600 size18 mb-1 mt-2">
          Rincian
        </h4>
        <hr>
        <div class="d-flex justify-content-between mb-1">
          <!-- Nama alias -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >Nama Alias</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0">
              {{ detail.alias_name || '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1">
          <!-- Nama Produk Induk -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >Nama Produk Induk</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0" >
              {{ detail.parent_product ? detail.parent_product.name || '-' : '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1">
          <!-- Merk -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >Merk</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0">
              {{ detail.brand && detail.brand.name || '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1">
          <!-- SKU Pabrik -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >SKU Pabrik</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0">
              {{ detail.sku_code || '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1">
          <!-- Jenis Barang -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >Jenis Barang</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0">
              {{ detail.name || '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1">
          <!-- Spesifikasi -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >Spesifikasi</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0">
              {{ detail.specification || '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1">
          <!-- Satuan -->
          <span
            class="text-dark text-darken-4 size14 fw-bold-500"
            style="width: 35%;"
          >Satuan</span>
          <div
            class=""
            style="width: 47%;"
          >
            <h6 class="text-dark size14 fw-bold-500 mb-0">
              {{ detail.unit || '-' }}
            </h6>
          </div>
          <div />
        </div>
        <div class="d-flex justify-content-between mb-1 flex-column" v-if="detail.stock_in_warehouse">
          <!-- Stok di Gudang -->
          <h6 class="fw-bold-600 text-dark">Stok di Lokasi SKU</h6>
          <div class="d-flex justify-content-between mb-1" v-for="warehouse, index in detail.stock_in_warehouse" :key="index">
            <span
              class="text-dark text-darken-4 size14 fw-bold-500"
              style="width: 35%;"
            >{{ warehouse.name || '-' }}</span>
            <div
              class=""
              style="width: 47%;"
            >
              <h6 class="text-dark size14 fw-bold-500 mb-0">
                {{ warehouse.stock || '-' }}
              </h6>
            </div>
            <div />
          </div>
        </div>
        <div class="d-flex justify-content-between mb-1 flex-column" v-else-if="detail.stock_in_supplier">
          <!-- Stok di Gudang -->
          <h6 class="fw-bold-600 text-dark">Stok di Lokasi SKU</h6>
          <div class="d-flex justify-content-between mb-1" v-for="supplier, index in detail.stock_in_supplier" :key="index">
            <span
              class="text-dark text-darken-4 size14 fw-bold-500"
              style="width: 35%;"
            >{{ supplier.name || '-' }}</span>
            <div
              class=""
              style="width: 47%;"
            >
              <h6 class="text-dark size14 fw-bold-500 mb-0">
                {{ supplier.stock || '-' }}
              </h6>
            </div>
            <div />
          </div>
        </div>
        <hr>
        <History v-if="detail" :uuid="detail.uuid" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BImg,
} from 'bootstrap-vue'

import History from '@/components/MasterProduct/Part/Riwayat.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    History,
  },
  props: {
    detail: {
      type: Object,
    },
  },
}
</script>
