var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center pt-5 pb-3 bg-white"},[_c('b-spinner')],1):_c('vue-good-table',{attrs:{"max-height":"80vh","columns":_vm.columns,"rows":_vm.stocks,"fixed-header":false,"sort-options":{
      enabled: false,
    },"pagination-options":{
      enabled: false,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field == 'action')?_c('span'):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'jumlah')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.stock)+" "+_vm._s(props.row.unit))])]):_vm._e(),(props.column.field === 'product_type')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.product_type === "parent" ? "Produk Induk" : "Produk Eceran"))])]):(props.column.field === 'name')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.name || "-"))])]):(
          props.column.field === 'stock_in_supplier' ||
          props.column.field === 'stock_in_warehouse'
        )?_c('span',[(props.row.stock_in_supplier)?_c('div',[(props.row.stock_in_supplier.length < 3)?_c('span',[_vm._v(_vm._s(props.row.stock_in_supplier .map(function (e) { return e.name; }) .slice(0, 3) .join(", ")))]):_c('b-dropdown',{staticClass:"delivery-numbers px-0",attrs:{"text":props.row.stock_in_supplier
                .map(function (e) { return e.name; })
                .slice(0, 3)
                .join(', '),"variant":"flat-secondary"}},[_c('b-row',{staticClass:"mx-0 pt-1"},_vm._l((props.row.stock_in_supplier),function(item,index){return _c('b-col',{key:index},[_c('h6',{staticClass:"text-dark"},[_vm._v(_vm._s(item.name))])])}),1)],1)],1):_vm._e(),(props.row.stock_in_warehouse)?_c('div',[(props.row.stock_in_warehouse.length < 3)?_c('span',[_vm._v(_vm._s(props.row.stock_in_warehouse .map(function (e) { return e.name; }) .slice(0, 3) .join(", ")))]):_c('b-dropdown',{staticClass:"delivery-numbers px-0",attrs:{"text":props.row.stock_in_warehouse
                .map(function (e) { return e.name; })
                .slice(0, 3)
                .join(', '),"variant":"flat-secondary"}},[_c('b-row',{staticClass:"mx-0 pt-1"},_vm._l((props.row.stock_in_warehouse),function(item,index){return _c('b-col',{key:index},[_c('h6',{staticClass:"text-dark"},[_vm._v(_vm._s(item.name))])])}),1)],1)],1):_vm._e()]):(props.column.field === 'action')?_c('span',[_c('div',{staticClass:"text-right"},[(
              _vm.isWarehouse
                ? _vm.checkPermission('detail aktual stok gudang')
                : _vm.checkPermission('detail aktual stok supplier')
            )?_c('b-button',{staticClass:"detail__button py-1 mr-25",staticStyle:{"box-shadow":"0px 4px 8px rgba(0, 0, 0, 0.04)"},on:{"click":function($event){return _vm.getDetail(props.row.uuid)}}},[_vm._v(" Lihat Detail ")]):_vm._e(),(props.row.stock < 0)?_c('b-button',{staticClass:"detail__button py-1",staticStyle:{"box-shadow":"0px 4px 8px rgba(0, 0, 0, 0.04)"},on:{"click":function($event){return _vm.$router.push({
                name: 'stock.opname',
                query: { ctaId: props.row.uuid, ctaName: props.row.name },
              })}}},[_vm._v(" Re-stock ")]):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),(_vm.rows)?_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap bg-white border",staticStyle:{"padding":"0.8rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '15', '20']},model:{value:(_vm.filter.per_page),callback:function ($$v) {_vm.$set(_vm.filter, "per_page", $$v)},expression:"filter.per_page"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Tampilkan "+_vm._s(_vm.rows.total > 0 ? _vm.filter.per_page * (_vm.rows.current_page - 1) + 1 : 0)+" ke "+_vm._s(_vm.rows.total > 0 ? Math.min(_vm.filter.per_page * _vm.rows.current_page, _vm.rows.total) : 0)+" dari "+_vm._s(_vm.rows.total)+" transaksi. ")])],1),_c('div',[_c('b-row',{staticClass:"mx-0 align-items-center"},[_c('b-col',[_c('pagination',{staticClass:"mb-0",attrs:{"data":_vm.rows,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getStockActual}})],1)],1)],1)]):_vm._e(),_c('ModalDetailStock',{attrs:{"detail":_vm.detail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }